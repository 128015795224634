import PxlApiClient from "openapi-client/src/ApiClient.js";

class Client extends PxlApiClient {
  constructor(basePath = "") {
    super(basePath);
    this.defaultHeaders = {
      "X-CSRFTOKEN": this.getCookie("csrftoken"),
    };
  }

  getCookie(name) {
    name = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
export default new Client();
